.signInForm{
    position: relative;
    bottom: -280px;
    width:300px;
    background: #f3f2ef;
    opacity: .8;
    padding: 20px;
    text-align: center;    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.signInForm h5{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 25px;
    font-weight: bold;
}

.signInForm input{
    display: block;
    width: 100%;
    padding: 0 16px;
    height: 44px;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    border: none;
}

.txtb{
    margin: 20px 0;
    background: #ffdd3e;
    opacity: .8;
    border-radius: 6px;
    transition: .8s;
    font-weight: 900;
}

.txtb:focus{
    transform: scale(1.05);
    border: #4595a4 2px solid;
}

.logbtn{
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 44px;
    cursor: pointer;
}

.loginMessage{
    margin-top: 20px;
    color: #ffdd3e;
    font-weight: bolder;
    background: #3e4e6d;
    opacity: .8;
}
.loginMessage p{
    margin: auto;
    padding: 5px;
}