.navbar {
    background-color: transparent !important;
    z-index: 2;
}

.nav-link {
    color: #3e4e6d !important;
    font-size: 18px;
    font-weight: bold;   
    /* border: white, solid, 5px !important;  */
}

.nav-link:hover {
    color: #ffdd3e !important;
    font-weight: bolder !important;
    text-shadow: 2px 2px 2px #4595a4;
    /* background: #4595a4 */
}

.active{
    color: #4595a4 !important;
}

.navbar-brand {
    color: #69acb5 !important;
    font-weight: bolder !important;
    font-size: 24px;
}