/* section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient( #69ACB5,#585858);
    animation: animate 20s linear infinite;
    z-index: -1;
}

@keyframes animate {
    0% {
        filter: hue-rotate(0deg);
    }
    50% {
        filter: hue-rotate(120deg);
    }
    100% {
        filter: hue-rotate(0deg);
    }
} */

.logos {
    position: absolute;
    width: 100%;
    height: 89vh;
    /* top: 0; */
    left: 0;
    overflow: hidden;
}

.logos2{
    transform: scale(1.5) rotateY(180deg);
    filter: blur(2px);
    width: 80%;
    height: 71vh;
    overflow: hidden;
}

.logos3{
    top: -12%;
    left: -13%;
    transform: scale(0.8) rotateX(180deg);
    filter: blur(4px);
    width: 125%;
    height: 122vh;
    overflow: hidden;
}

.logos div {
    position: absolute;
    display: block;
}

.logos div:nth-child(1) {
    left: 5%;
    animation: logoanimate 15s linear infinite;
}
.logos div:nth-child(2) {
    left: 23%;
    animation: logoanimateleft 23s linear infinite;
    animation-delay: -12s;
}
.logos div:nth-child(3) {
    left: 71%;
    animation: logoanimate 18s linear infinite;
    animation-delay: -7s;
}
.logos div:nth-child(4) {
    left: 52%;
    animation: logoanimateleft 25s linear infinite;
    animation-delay: -10s;
}
.logos div:nth-child(5) {
    left: 93%;
    animation: logoanimate 18s linear infinite;
    animation-delay: -8s;
}
.logos div:nth-child(6) {
    left: 42%;
    animation: logoanimateleft 20s linear infinite;
    animation-delay: -6s;
}
.logos div:nth-child(7) {
    left: 33%;
    animation: logoanimate 17s linear infinite;
    animation-delay: -18s;
}
.logos div:nth-child(8) {
    left: 83%;
    animation: logoanimateleft 16s linear infinite;
    animation-delay: -4s;
}
.logos div:nth-child(9) {
    left: 15%;
    animation: logoanimate 19s linear infinite;
    animation-delay: -9s;
}
.logos div:nth-child(10) {
    left: 63%;
    animation: logoanimateleft 12s linear infinite;
    animation-delay: -13s;
}

@keyframes logoanimate {
    0%{
        top: 0%;
        opacity: 0;
        transform: translateX(-20px) rotate(0deg);
    }
    20%{
        opacity: 0.8;
        transform: translateX(20px) rotate(45deg);
    }
    40%{
        transform: translateX(-20px) rotate(90deg);
    }
    60%{
        transform: translateX(20px) rotate(135deg);
    }
    80%{
        transform: translateX(-20px) rotate(180deg);
    }
    100%{
        top: 100%;
        transform: translateX(20px) rotate(225deg);
    }
}

@keyframes logoanimateleft {
    0%{
        top: 0%;
        opacity: 0;
        transform: translateX(-20px) rotate(0deg);
    }
    20%{
        opacity: 0.5;
        transform: translateX(20px) rotate(-45deg);
    }
    40%{
        transform: translateX(-20px) rotate(-90deg);
    }
    60%{
        transform: translateX(20px) rotate(-135deg);
    }
    80%{
        transform: translateX(-20px) rotate(-180deg);
    }
    100%{
        top: 100%;
        transform: translateX(20px) rotate(-225deg);
    }
}

.bkg-img{
    /* position: absolute; */
    height: 10vh;
    /* top: 10%; */
    z-index: 1;
}