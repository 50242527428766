.contact-logos{    
    display: inline;
    max-width: 35px;
    margin: 2px 20px;
    position: relative;
    bottom: 2px;    
}

section{
    position: fixed;
    width: 100%;
    /* height: 100vh; */
    overflow: hidden;
    /* margin-top: 100px; */
    bottom: 0%;
    z-index: 0;
    
}
section .scroll{
    width: 100%;
    display: flex;
    background: #F3F2EF;
    opacity: .8;
    border: 1px solid #4595a4;
    padding: 3px 2px 0px 2px;
}

section .scroll div{
    white-space: nowrap;
    animation: moveLeft 20s linear 1;
    letter-spacing: 5px;
    font-weight: bolder;
    position: relative;
    left: 30%;
}

@keyframes moveLeft{
    0%{
        transform: translateX(190%);
    }
    100%{
        transform: translateX(-180%);
    }
}

#social-text{
    margin-right: 5%;
}

#social-text1::before{
    content: "ALSO IN SOCIAL MEDIA";
}

@media only screen and (max-width: 756px) {
    #social-text1::before{
        content: "";
    }
}

@media only screen and (max-width: 576px) {
    section{
        position: fixed;
        width: 100%;
        /* height: 100vh; */
        overflow: hidden;
        /* margin-top: 100px; */
        /* top: 11%; */
        bottom: 0%;
        right: -90%;
        z-index: 0;
        
    }
    section:hover{
        position: fixed;
        right:0%;
    }
    #social-text1::before{
        position: relative;
        content: "<";
        font-weight: bolder;
        left: -45%;
        margin-right: -20%;
        
    }
}