body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background-image: url(/images/kb-background.jpg);
  background-size: cover;
  background-position: top; */

  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-family: 'Raleway', sans-serif;
  color: #3e4e6d;
  background-image: linear-gradient(250deg,#fffffe, #f3f2ef, #cfcfcf, #d4d3d4);
  background-size: 400% 2400%;
  animation: bkganimation 15s infinite;
  z-index: -1;
  /* background-size: cover; */
}
/* .App {
  position: relative;
} */

.prettyBut{
  background-size: 200% !important;
  background-image: linear-gradient(to left, #4595a4, #f3f2ef, #4595a4) !important;
  transition: 0.5s !important;
  font-weight: 900 !important;
  border: none !important;
}

.prettyBut:hover{
  background-position: right !important;
  transform: scale(1.05) !important;
  border: #ffdd3e 2px solid !important;
}

@keyframes bkganimation {
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
