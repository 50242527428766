*{
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.proj-cont{
    position: relative;
    padding: 5px 5px;
    margin: 0;
    /* border: 1px solid #ffdd3e; */
}

.proj-cont .descr{
    position: absolute;
    top: 0;
    left: 0;
    background: #3e4e6d;
    width: 100%;
    height: 98%;
    opacity: 0;
    color: #f3f2ef;
    font-size: .8em;
    transition: opacity 1.5s;
    overflow-y: scroll;
}

.proj-cont:hover .descr{
    opacity: .8;
}

.proj-cont .descr .title {
    color: #ffdd3e;
    text-align: center;
    margin-top: 6%;
    margin-bottom: 5%;
    font-weight: 700;
}

.proj-cont .descr .descript{
    text-align: justify;
    margin: 5%;
}

.proj-cont .descr .tech{
    text-align: justify;
    margin: 5%;
}

.proj-cont .descr .sm{
    margin: 20px 0;
    overflow: hidden;
    margin: 5%;
}

.sm p{
    display: inline-flex;
    justify-content: center;
    align-items: center;    
}

.logoLink {
    position: absolute;
    bottom: -30px;
    right: 5%;
}

.logoLink a{
    margin-left: 11%;
}

/* .card-cont{
    height: 40vh;
    margin: 0%;
    padding: 0%;
    display: table-cell;
    flex: 1 1 auto;
}
.project-list{
    margin-top: 10%;
    margin-bottom: 3%;
    margin-left: 2%;
    margin-right: 2%;
    padding: 10%;    
    width: 100%;
    display: table;
    table-layout: fixed;
}

.proj-card{
    cursor: pointer;    
}

.front{
    width: 100%;
    height: 81.1%; 
    overflow: hidden;
    position: absolute;
    left: 0%;
    padding: 3px;
}

.back{
    width: 100%;
    min-height: 81.1%; 
    overflow: hidden;
    position: absolute;
    left: 0%;
    padding: 3px;
    background: #3e4e6d;
    opacity: 0;
    transition: opacity 1.5s;
}

.front img{
    height: 100%;
}

.proj-desc{    
    width: 100%;
    font-size: 0.7em;
    color: #f2f2ef;
}

.proj-desc .title{
    color: #ffdd3e;
    text-align: center;
    margin-top: 7%;
    margin-bottom: 5%;
    font-weight: 700;
}

.proj-desc .descript{
    text-align: justify;
    margin: 5%;
}

.proj-desc .tech{
    text-align: justify;
    margin: 5%;
}
.sm{
    margin: 20px 0;
    overflow: hidden;
    margin: 5%;
}

.sm p{
    display: inline-flex;
    justify-content: center;
    align-items: center;    
}

.logoLink {
    position: absolute;
    bottom: 1px;
    right: 5%;
}

.logoLink a{
    margin-left: 11%;
}

.proj-card:hover > .back{
    opacity: .8;
} */