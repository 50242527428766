#about-content {
    padding: 20px 10px;
    max-height: 100vh;
    color: #3e4e6d;
    list-style: none;
}

#about-card, #skills-card {
    max-width: 700px;
    margin: auto;
    /* background: rgba(243,243,239,.8); */
    background: transparent;
    padding: 1%;
    border: none;
    /* border: 2px #585858 solid;     */
    
}

hr{
    border: 1px solid #4595A4;
    /* color: #4595A4; */
}

#about-card {
    /* position: absolute; */
    left: 0%;
    animation: toRight 2s;
}

@keyframes toRight {
    0%{
        left: -150%;
    }
    100%{
        left: 0%;
    }
}

#skills-card {
    /* position: absolute;
    display: block; */
    right: 0%;
    animation: toLeft 2s;
}

@keyframes toLeft {
    0%{
        right: -150%;
    }
    100%{
        right: 0%;
    }
}

#img-profile {
    /* max-height: 115px; */
    max-width: 150px;
    margin-left: 30%;
}

.first-letter {
    font-size: 180%;
}

.card-title {
    font-weight: bold;
    letter-spacing: 6px;
}

#green-button {
    font-weight: bold;
    border: 2px #9cc589 solid;
    color: #9cc589;
}

.text-fade-in{
    opacity: 1;
    animation: fadeIn 4s;
}

@keyframes fadeIn {
    0%{
        opacity:.05;
    }
    50%{
        opacity:.05;
    }
    100%{
        opacity: 1;
    }

}

.portfolio-img {
    position: relative;
    height: 25px;
    width: auto; 
    margin: auto;
    top: -3px;
    left: 3px
}

.about-text {
    font-size: 16px;
}

.card-text li {
    margin: 20px 0;
}

.skill-bar {
    background: #4595A4;
    display: block;
    height: 2px;
    /* border: 1px solid rgba(0,0,0,.3); */
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 15px #ffdd3eb3;
}

.skill-bar span {
    height: 2px;
    float: left;
    background: #ffdd3e;
}

.html {
    width: 90%;
    animation: html 4s;
}

.css {
    width: 75%;
    animation: css 4s;
}

.javascript {
    width: 85%;
    animation: javascript 4s;
}

.react {
    width: 75%;
    animation: react 4s;
}

.nodejs {
    width: 65%;
    animation: nodejs 4s;
}

.mongo {
    width: 60%;
    animation: mongo 4s;
}

.php {
    width: 15%;
    animation: php 4s;
}

@keyframes html {
    0%{
        width: 0%;        
    }
    50%{
        width: 0%;        
    }
    100%{
        width: 90%;
    }
}
@keyframes css {
    0%{
        width: 0%;        
    }
    50%{
        width: 0%;        
    }
    100%{
        width: 65%;
    }
}@keyframes javascript {
    0%{
        width: 0%;        
    }
    50%{
        width: 0%;        
    }
    100%{
        width: 75%;
    }
}@keyframes react {
    0%{
        width: 0%;        
    }
    50%{
        width: 0%;        
    }
    100%{
        width: 60%;
    }
}@keyframes nodejs {
    0%{
        width: 0%;        
    }
    50%{
        width: 0%;        
    }
    100%{
        width: 50%;
    }
}@keyframes mongo {
    0%{
        width: 0%;        
    }
    50%{
        width: 0%;        
    }
    100%{
        width: 60%;
    }
}@keyframes php {
    0%{
        width: 0%;        
    }
    50%{
        width: 0%;        
    }
    100%{
        width: 15%;
    }
}