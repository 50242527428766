.index-jumbotron {
    text-align: center;
    position: relative;
    margin-top: 14%;
    /* top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); */
    width: 100%;
}

#wd-title {
    
    color: #3e4e6d;
    text-shadow: 3px 3px 5px #ffdd3e;
    font-weight: bolder;
    display: block;
    font-size: 40px;
    letter-spacing: 5px;
    margin-bottom: 20px;
    position: relative;
    animation: appear 3s 1;
}

#wd-paragraph {
    font-size: 30px; 
    color: #4595A4;
    /* color: transparent; */
    animation: changecolor 3s 1;  
    font-weight: bold;  
}

@keyframes appear {
    0%{
        margin-bottom: -50px;
        font-size: 20px;
    }
    30%{
        letter-spacing: 13px;
        font-size: 40px;
        margin-bottom: -50px;
    }
    60%{
        letter-spacing: 5px;
        font-size: 30px;
        margin-bottom: -50px;
    }
    80%{
        margin-bottom: 50px;
    }
}

@keyframes changecolor{
    0%{
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    85%{
        opacity: 0.5;
    }

    95%{
        opacity: 1
    }
}


.first-letter {
    font-size: 180%;
}