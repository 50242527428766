.blue-button {
    font-weight: bold;
    border: 2px #3e4e6d solid;
    color: #3e4e6d;
}
.blue-button:hover{
    color: #F3F2EF;
    background: #3e4e6d;
    border: 2px #3e4e6d solid;
}

#contactSubmit{
    display: block;
    width: 25%;
    padding: 0 16px;
    height: 44px;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 44px;
    cursor: pointer;
}

.container{
    max-width: 90%;
}
.accordion{
    opacity: .8;
}

.card-header{
    padding: 2px;
    background: #F3F2EF;
    opacity: .8;
}

button.btn {
    padding: 2px;
    background: #F3F2EF;
    opacity: .8;
    text-decoration: none;
}
button.btn span{
    font-size: 12px;
    font-style: oblique;
    margin: 0%;
    padding: 0%;
    color: #3e4e6d;
    font-weight: bold;
    
}

.container-margin{
    margin-top: 10px;
}

.form-control{
    background: #F3F2EF;
    opacity: .8;
    font-size: 12px;
    
}
::placeholder{
    color: #3e4e6d;
}

.card-body{
    background: #F3F2EF;
    opacity: .8;
    font-size: 12px;
}
